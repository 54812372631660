import { delay } from '@st/utils'
import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'
import { useUserStore } from '@st/user/stores/useUserStore'
import { useSettingsStore } from '@st/core/stores/useSettingsStore'

export function useReferralBonus() {
  const { currencies } = storeToRefs(useCurrenciesStore())
  const { user } = storeToRefs(useUserStore())
  const { settings } = storeToRefs(useSettingsStore())
  const readMoreUrl = usePublicFileUrl('publicFileReferralProgramTerms')

  const { data: referralProgramData } = useStFetch(
    '/referral-program/info/get',
    {
      method: 'post',
    },
  )

  const referralProgramCurrencyId = computed(
    () => settings.value?.referralProgramCurrencyId,
  )
  const currencySettings = computed(() => settings.value!.currencySettings)
  const referralCurrencySettings = computed(() => {
    if (!referralProgramCurrencyId.value) return undefined

    return currencySettings.value[referralProgramCurrencyId.value]
      .referralProgram
  })

  const rewardAmount = computed(() => {
    if (!referralCurrencySettings.value) return undefined

    return referralCurrencySettings.value.referrerRewardAmount
  })
  const thresholdAmount = computed(() => {
    if (!referralCurrencySettings.value) return undefined

    return referralCurrencySettings.value.totalBetThresholdAmount
  })
  const referralCode = computed(() => user.value?.referralCode ?? '')
  const referralLink = computed(() => {
    const referalHostname = 'https://bet1.promo/?ref='

    return `${referalHostname}${referralCode.value}`
  })

  const referralCount = computed(
    () => referralProgramData.value?.referralNumber,
  )
  const referralRewardCount = computed(
    () => referralProgramData.value?.referralAccrualNumber,
  )

  const referralGottenAmount = computed(
    () => referralProgramData.value?.totalReferralAccrualAmount,
  )
  const referralCurrency = computed(() => {
    if (!referralProgramCurrencyId.value) return undefined

    return currencies.value[referralProgramCurrencyId.value]
  })

  const { format: formatCrypto } = useCryptoFormatter({
    currency: computed(() => referralCurrency.value?.code ?? 'USDT'),
  })

  const formattedReward = computed(() => formatCrypto(rewardAmount.value ?? 0))
  const formattedTrashHold = computed(() =>
    formatCrypto(thresholdAmount.value ?? 0),
  )

  const isCopyed = ref(false)
  const { copy } = useClipboard()
  async function handleCopy(value: string) {
    copy(value)
    isCopyed.value = true
    await delay(3000)
    isCopyed.value = false
  }

  return {
    rewardAmount,
    thresholdAmount,
    referralCode,
    referralCount,
    referralRewardCount,
    referralGottenAmount,
    referralCurrency,
    referralLink,
    isCopyed,
    readMoreUrl,
    formattedTrashHold,
    formattedReward,
    handleCopy,
  }
}
